<template>
    <div class="offer-list">
        <vx-card>
            <vue-bootstrap4-table
                    v-if="dataFetched"
                    :rows="data"
                    :columns="columns"
                    :config="config"
                    :actions="actions"
                    :classes="{table: 'row-select'}"
                    @on-change-query="onChangeQuery"
                    @on-change-per-page="onChangePerPage"
                    :total-rows="total_rows"
                    columnSelection>

                <template slot="empty-results">
                    Keine Angebote gefunden.
                </template>
                %
                <template slot="row_actions" slot-scope="props">
                    <div class="column-actions-group">
                        <router-link v-if="showEditButton(props.row.status)" :to="'/offers/'+props.row.id+'/edit/'">
                            <vx-tooltip text="Bearbeiten" position="left">
                                <vs-button color="success" type="filled" icon="edit"
                                           size="small"
                                           :to="'/offers/'+props.row.id+'/edit/'"></vs-button>
                            </vx-tooltip>
                        </router-link>


                        <vx-tooltip text="Einsehen" position="left">
                            <router-link v-if="!showEditButton(props.row.status)  && !props.row.type.is_confirmation"
                                         :to="'/offers/'+props.row.id+'/preview/'">
                                <vs-button color="warning" type="filled"
                                           icon="search" size="small"
                                           :to="'/offers/'+props.row.id+'/preview/'"></vs-button>
                            </router-link>

                            <router-link v-if="!showEditButton(props.row.status) && props.row.type.is_confirmation"
                                         :to="'/order-confirmation/view/'+props.row.id">
                                <vs-button color="warning" type="filled"
                                           icon="search" size="small"
                                           :to="'/order-confirmation/view/'+props.row.id"></vs-button>
                            </router-link>
                        </vx-tooltip>


                        <vx-tooltip text="Duplizieren & bearbeiten" position="left">
                            <router-link v-if="!props.row.type.is_confirmation" :to="'/offers/duplicate/'+props.row.id">
                                <vs-button color="dark" type="border"
                                           icon="content_copy" size="small"
                                           :to="'/offers/duplicate/'+props.row.id"></vs-button>
                            </router-link>
                        </vx-tooltip>

                    </div>
                </template>

                <template slot="table_tools_left">
                    <v-select
                            v-model="selectedOrderType"
                            :options="orderTypes"
                            autowidth
                            label="name"
                            class="w-1/3"
                            :searchable="false"
                            placeholder="Typ filtern"
                            @input="changeOrderType">
                    </v-select>
                </template>

                <template slot="status" slot-scope="props">
                    <span class="vbt-table-td">
                        <span v-html="orderStatusFormatter(props.row.status)"></span>
                        <span class="style-ab" v-if="props.row.order_type_id === 6">AB</span>
                    </span>
                </template>

                <template slot="offer_billing_type" slot-scope="props">
                    <span class="vbt-table-td offer_billing_type">
                        <span v-if="props.row.offer_billing_type">
                            <vx-tooltip position="bottom">
                                {{props.row.offer_billing_type.name}}
                                  <template v-slot:text>
                                      <div v-if="props.row.last_accountings != null">
                                          <b>Letzte 3 Abrechnungen: </b><br>
                                          <vs-row v-for="(accounting, index) in props.row.last_accountings" :key="accounting.id" style="border-bottom: 1px solid #eee">
                                              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="1">
                                                    {{(index+1)}}.
                                                </vs-col>
                                                <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="5">
                                                    <small>{{accounting.article_name}}
                                                        <div class="circle-container" v-if="!accounting.order_id"><div  class="circle-red"></div> Noch in der Abrechnung</div>
                                                        <div class="circle-container green" v-if="accounting.order_id"><div  class="circle-green"></div> Rechnung vorhanden</div>
                                                    </small>
                                                </vs-col>
                                                <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="3">
                                                    <small>{{Number(accounting.amount).toFixed(2)}}</small>
                                                </vs-col>
                                               <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="3">
                                                   <small>{{accounting.amount*accounting.unit_price }} €</small>
                                                </vs-col>
                                          </vs-row>
                                      </div>
                                      <div v-if="props.row.last_accountings == null"><b>Bisher keine Abrechnung</b></div>
                                  </template>

                            </vx-tooltip>
                        </span>
                        <span v-else>-</span>
                    </span>
                </template>

                <template slot="creator" slot-scope="props">
                    <span class="vbt-table-td">
                        <div class="flex  mb-4">
                            <gravatar-img :email="props.row.creator.email" class="rounded-full shadow-md" width="30"
                                          height="30"/>
                            <span
                                    class="mt-1 ml-2">{{
                                props.row.creator.detail.first_name
                              }} {{ props.row.creator.detail.last_name }}</span>
                        </div>
                    </span>
                </template>

                <template slot="table-last-row">
                    <tr class="vbt-row-summary" v-if="userHasPermission('edit_open_orders')">
                        <td colspan="2">
                            <span class="vbt-table-td">{{ total_rows }} Ergebnisse</span>
                        </td>
                        <td colspan="4" align="right"></td>
                        <td colspan="2">
                            <span class="vbt-table-td"><b>Gesamt: {{ pageSum }}</b></span>
                        </td>
                    </tr>
                </template>
            </vue-bootstrap4-table>
        </vx-card>
    </div>
</template>

<script>
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

    import ApiService from "../../../api";
    import findIndex from "lodash/findIndex";
    import moment from 'moment';
    import QueryHelper from "../../../mixins/helper/query.helper";
    import * as qs from "qs";
    import PriceHelper from "../../../mixins/helper/price.helper";
    import vSelect from 'vue-select'
    import {mapGetters} from "vuex";
    import GravatarImg from "vue-gravatar/src/components/GravatarImg";

    export default {
        name: "Offers",
        components: {
            VueBootstrap4Table,
            'v-select': vSelect,
            GravatarImg
        },
        data() {
            return {
                dataFetched: false,
                data: [],
                billing_types: [],
                actions: [
                    {
                        btn_text: "Hinzufügen",
                        router_link: '/offers/add',
                        color: 'success'
                    }
                ],
                columns: [
                    {
                        label: "Status",
                        name: "status",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                        width: '150px',
                        slot_name: "status",
                        filter: {
                            type: 'select',
                            options: [],
                            mode: 'multi',
                            value: [{"value": 13, "label": "Entwurf"}, {
                                "value": 14,
                                "label": "Versendet"
                            }, {"value": 15, "label": "In Verhandlung"}, {
                                "value": 16,
                                "label": "Akzeptiert"
                            }, {"value": 19, "label": "im Projekt"}]
                        },
                    },
                    {
                        label: "Nummer",
                        width: '150px',
                        name: "invoice_number",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                    {
                        label: "Title",
                        name: "title",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        width: '25%',
                        showCol: true,
                    },
                    {
                        label: "Kunde",
                        name: "client.company_name",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        width: '200px',
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                    {
                        label: "Datum",
                        name: "order_date",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                        formatter: this.formatDateTime,
                        width: '220px',
                        initial_sort: true,
                        initial_sort_order: 'desc',
                        filter: {
                            type: 'date',
                            mode: 'range'
                        },
                    },
                    {
                        label: "Gültig bis",
                        width: '150px',
                        name: "offer_valid_to",
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                        formatter: this.formatDateTime,
                        initial_sort: true,
                        initial_sort_order: 'desc',
                        filter: {
                            type: 'date',
                            mode: 'range'
                        },
                    },
                    {
                        label: "Fällig",
                        name: "overdue_date",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                        slot_name: "overdue",
                        width: '220px',
                        filter: {
                            type: 'date',
                            mode: 'range'
                        },
                    },
                    {
                        label: "Positionen",
                        name: "positions",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                        slot_name: "positions",
                        width: '220px',
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },

                    },
                    {
                        label: "Betrag",
                        name: "sum_netto",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        formatter: PriceHelper.formatCurrency,
                        width: '10%',
                        showCol: true,
                    },
                    {
                        label: "Notiz",
                        name: "notice",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        width: '10%',
                        showCol: false,
                    },
                    {
                        label: "Ansprechpartner",
                        name: "employee_id",
                        slot_name: "creator",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'type-column',
                        filter: {
                            type: "select",
                            placeholder: "Auswählen",
                            options: [],
                            mode: 'single',
                            value: {"value": 40, "label": "Aaron Löchner"}
                        },
                        showCol: true,
                    },
                    {
                        label: "Abrechnungstyp",
                        name: "offer_billing_type_id",
                        slot_name: "offer_billing_type",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'type-column',
                        filter: {
                            type: "select",
                            placeholder: "Auswählen",
                            options: [],
                            mode: 'single',
                            value: null
                        },
                        showCol: true,
                    },
                    {
                        label: "Actions",
                        slot_name: "row_actions",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'actions-column',
                        showCol: true,
                        width: '80px',

                    }],
                config: {
                    checkbox_rows: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8',
                    server_mode: true,
                    global_search: {
                        visibility: true
                    },
                    per_page: 10
                },
                queryParams: {
                    sort: [{
                        caseSensitive: true,
                        name: "order_date",
                        order: "asc"
                    }],
                    filters: [],
                    global_search: "",
                    per_page: 10,
                    page: 1,
                },
                total_rows: 0,
                orderTypes: [],
                selectedOrderType: {
                    id: 0,
                    name: 'Alle Typen',
                    is_cancellation: 0
                },
            }
        },

        created() {
            this.fetchData(true)
        },
        computed: {
            ...mapGetters(['userHasPermission', 'userId', 'user','workspace']),
            requestParams() {
                let queryParams = QueryHelper.parseRequestParams(this.queryParams);

                queryParams.filter.push({
                    field: 'type.invoice_type',
                    value: 'offer',
                    expression: 'exact',
                });

                if (this.selectedOrderType !== null && this.selectedOrderType.id !== 0) {
                    queryParams.filter.push({
                        field: 'type.id',
                        value: this.selectedOrderType.id,
                        expression: 'exact'
                    });
                }


                return queryParams;
            },
            pageSum() {
                if (!this.data) {
                    return PriceHelper.formatCurrency(0);
                }

                const sum = this.data.reduce((acc, item) => {
                    return acc + item.sum_netto;
                }, 0);

                return PriceHelper.formatCurrency(sum);
            }
        },
        methods: {
            async fetchData(initial = false) {
                this.$vs.loading();

                if (!initial) {
                    return this.fetchOffers();
                } else {
                    Promise.all([this.orderTypesPromise(), this.orderStatusPromise(), this.employeesPromise(), this.orderBillingTypePromise()]).then(response => {
                        this.applyOrderTypeOptions(response[0].data.result.result);
                        this.applyStatusOptions(response[1].data.result);
                        this.applyEmployeeOptions(response[2].data.result);
                        this.applyBillingTypeOptions(response[3].data.result);
                        this.dataFetched = true;
                    });
                }
            },
            fetchOffers() {
                ApiService.get('orders', {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.data = response.data.result;
                    this.total_rows = response.data.total;
                    this.$vs.loading.close()
                })
            },
            orderTypesPromise() {

                let queryParams = {
                    language: 'en',
                    filter: [{
                        field: 'invoice_type',
                        value: 'offer',
                        expression: 'exact'
                    }]
                };

                return ApiService.get('order_types', {
                    params: queryParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                });
            },
            orderStatusPromise() {
                let queryParams = {
                    filter: [
                        {
                            field: 'invoice_type',
                            expression: 'exact',
                            value: 'offer'
                        }
                    ]
                };

                return ApiService.get('order_status', {
                    params: queryParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                });
            },
            orderBillingTypePromise() {
                return ApiService.get('offer_billing_types');
            },
            employeesPromise() {
                return ApiService.get('users');
            },
            applyOrderTypeOptions(options) {
                this.orderTypes = options;

                const allTypes = {
                    id: 0,
                    name: 'Alle Typen',
                    is_cancellation: 0
                };

                this.orderTypes.unshift(allTypes)
            },
            applyStatusOptions(options) {
                this.order_status = options;

                options = [];

                this.order_status.forEach((status) => {
                    options.push({
                        value: status.id,
                        label: status.name
                    })
                });

                this.columns[0].filter.options = options;
            },
            applyBillingTypeOptions(options) {
                this.billing_types = options;

                options = [];

                this.billing_types.forEach((type) => {
                    options.push({
                        value: type.id,
                        label: type.name
                    })
                });

                let index = findIndex(this.columns, {name: 'offer_billing_type_id'});

                if (index !== -1) {
                    this.columns[index].filter.options = options;
                }
            },
            applyEmployeeOptions(options) {
                options = options.map(option => {
                    if (option.detail.first_name != 'SYSTEM') {
                        return {value: option.id, label: option.detail.first_name + ' ' + option.detail.last_name};
                    }
                });

                options = options.filter(function (el) {
                    return el != null;
                });

                options = options.sort((a, b) => a.label.localeCompare(b.label));

                let index = findIndex(this.columns, {name: 'employee_id'});

                if (index !== -1) {
                    this.columns[index].filter.options = options;
                    this.columns[index].filter.value = {
                        "value": this.userId,
                        "label": this.user.detail.first_name + " " + this.user.detail.last_name
                    };
                }
            },

            changeOrderType() {
                this.fetchData();
            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            onChangeQuery(queryParams) {
                this.queryParams = queryParams;
                this.fetchData();
            },
            onChangePerPage(value) {
                this.config.per_page = value;
            },
            orderStatusFormatter(value) {
                const status = this.getOrderStatusById(value);

                if (!status) {
                    return value;
                }

                return status.label;
            },
            getOrderStatusById(id) {
                id = parseInt(id);
                return this.order_status.find(item => item.id === id);
            },
            formatDateTime(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            getDocumentUrl(order) {
                return `${process.env.VUE_APP_API_URL}document/${this.workspace.id}/order/${order.type.invoice_type}/${order.file}`;
            },
            showEditButton(id) {
                const status = this.getOrderStatusById(id);

                if (!status) {
                    return false;
                }

                return status.is_draft === 1;
            },
        },
    }
</script>

<style lang="scss">
    span.style-ab {
        background: #3c3c44;
        border-radius: 3px;
        padding: 5px 5px 3px 6px;
        color: #fff;
        font-size: 12px;
    }

    .circle-container {
        width: 100%;
        color: #ff8d00;
        font-weight: bold;
        &.green {
            color: #23a000;
        }
        .circle-red {
            width: 10px;
            height: 10px;
            background-color: #ff8d00;
            position: relative;
            float:left;
            margin-right: 5px;
            border-radius: 10px;
        }
        .circle-green {
            width: 10px;
            height: 10px;
            background-color: #23a000;
            position: relative;
            float:left;
            margin-right: 5px;
            border-radius: 10px;
        }
    }

</style>